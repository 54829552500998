/* eslint-disable */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'font-awesome/css/font-awesome.min.css'
Vue.use(ElementUI)
import axios from 'axios'
import { nanoid } from 'nanoid'
const sessionId  = nanoid()
axios.interceptors.request.use(function (config) {		// 在发送请求之前做一些事情
  config.headers.auth =localStorage.getItem('auth')
  config.headers.sid= sessionId
  return config;
}, function (error) {									//处理请求错误
  return Promise.reject(error);		
});

Vue.prototype.$http = axios
Vue.config.productionTip = false
Vue.config.devtools = true
router.beforeEach((to, from, next) => {
  document.getElementById('public_title').innerHTML = to.meta.pageTitle === undefined ? 'timelinegeo' : to.meta.pageTitle
  next();
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
