<template>
  <div>
    <div class="el-menu-demo1">
      <el-menu :default-active="activeIndex" background-color="#737373" text-color="#ffffff" mode="horizontal" router
        :collapse="false">
        <el-menu-item index="/">Home</el-menu-item>
        <el-menu-item index="/map">History Map</el-menu-item>
        <el-menu-item index="/map-cmp">Compare</el-menu-item>
        <el-menu-item index="/subject">Subject</el-menu-item>
        <el-menu-item index="/donate">Donate</el-menu-item>
        <el-menu-item index="/about">About</el-menu-item>
      </el-menu>
      <div class="login-regon">
        <el-button type="info" v-show="userInfo == null" @click="gologin">login in</el-button>
        <el-dropdown v-show="userInfo != null" trigger="click" @command="handleCommand">
          <el-button type="info">{{ userInfo?.username }}<i class="el-icon-arrow-down el-icon--right"></i></el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="user">usercenter</el-dropdown-item>
            <el-dropdown-item command="logout">logout</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="el-menu-demo2">
      <el-menu :default-active="activeIndex" background-color="#737373" mode="horizontal" text-color="#ffffff" router
        :collapse="false">
        <el-submenu index="2">
          <template slot="title"><i class="el-icon-menu cffffff"></i></template>
          <el-menu-item index="/">Home</el-menu-item>
          <el-menu-item index="/map">History</el-menu-item>
          <el-menu-item index="/map-cmp">Compare</el-menu-item>
          <el-menu-item index="/subject">Subject</el-menu-item>
          <el-menu-item index="/donate">Donate</el-menu-item>
          <el-menu-item index="/about">About</el-menu-item>
          <el-menu-item index="/login">login in</el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { Config } from '../config/config.js'
export default {
  name: 'navBar',
  props: {
    cIndex: {
      type: String,
      default: '1'
    },
    cIndex2: {
      type: String,
      default: '1'
    }
  },
  data () {
    return {
      activeIndex: '1',
      activeIndex2: '1',
      userInfo: null
    }
  },
  mounted () {
    this.activeIndex = this.cIndex
    this.activeIndex2 = this.cIndex2
    const user = localStorage.getItem('user')
    if (user) {
      this.userInfo = JSON.parse(user)
    }
  },
  methods: {
    gologin () {
      this.$router.push('login')
    },
    handleCommand (c) {
      if (c === 'logout') {
        localStorage.clear()
        window.location.href = Config.fehost
      } else if (c === 'user') {
        if (!this.$route.fullPath.startsWith('/user')) 
        this.$router.push('user')
      }
    }
  }
}
</script>
<style scoped >
@media screen and (max-width: 960px) {
  .el-menu-demo1 {
    display: none;
    height: 0;
  }
}

@media screen and (min-width: 960px) {
  .el-menu-demo2 {
    display: none;
  }
}

.cffffff {
  color: #ffffff;
}

.login-regon {
  float: right;
  position: relative;
  right: 10px;
  top: -50px;
}</style>
