<template>
  <el-container>
    <el-main>
      <div class="block">
        <span class="demonstration">Example: Changes in the territory of the Ottoman Empire</span>
        <el-slider v-model="cYear" :step="1" :min="min" :max="max" :format-tooltip="formatTooltip" @change="changeYear">
        </el-slider>
      </div>
      <div id="ttmap">
        <div id="tmap"></div>
      </div>
    </el-main>
    <el-footer>Designed by daoyuan997 <el-link type="info"
        href="mailto://daoyuan997@gmail.com">feedback:daoyuan997@gmail.com</el-link>
        <br/>More subject are in the process. You can send an email to the above email address
      </el-footer>
  </el-container>
</template>
<script>
/* eslint-disable */
import navBar from '../components/NavBar.vue'
import mapboxgl from "mapbox-gl";
import "../assets/mapbox.css";
// import 'mapbox-gl/dist/mapbox-gl.css'
import "mapbox-gl-style-switcher/styles.css"
import { Config } from '../config/config.js'
export default {
  data() {
    return {
      cYear: 1298,
      max: 1899,
      min: 1299,
      zoom: 3,
      minZoom: 0,
      maxZoom: 9,
      map: null,
    }
  },
  components: {
    navBar
  },
  methods: {
    formatTooltip(val) {
      return 'AD' + val;
    }
    , changeYear(y) {
      console.log(y)
      this.cYear = y
      for(let i=1299; i <= y-1;i++){
        this.map.setLayoutProperty("history_aosiman_"+i,'visibility','none')
        this.map.setLayoutProperty("history_aosiman_"+i,'visibility','none')
      }
      for(let i=y+1; i <= 1899;i++){
        this.map.setLayoutProperty("history_aosiman_"+i,'visibility','none')
        this.map.setLayoutProperty("history_aosiman_"+i,'visibility','none')
      }
      this.map.setLayoutProperty("history_aosiman_"+y,'visibility','visible')
      this.map.setLayoutProperty("history_aosiman_"+y,'visibility','visible')

    }, format(year) {
      if (year > 0) {
        return 'AD' + year
      } else {
        return 'BC' + year
      }
    },
  },
  mounted: async function () {
    let year = this.format(this.currentYear)
    let { data } = await this.$http.get(Config.host + '/api/tile/style/' + 'AD1298')
    this.style = data
    console.log(data)
    data['sources']['aosiman'] = { 'tiles': [Config.host+"/tile/data/aosiman/{z}/{x}/{y}.pbf"], type: "vector" }
    this.map = new mapboxgl.Map({
      container: "tmap",
      style: data,
      pitchWithRotate: false,
      dragRotate: false,
      zoom: this.zoom,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      center: [30, 20],
      preserveDrawingBuffer: true
    });
    this.map._authenticate = function () { };
    this.map.on("style.load", () => {
      this.map.setFog({});
      for (let year = 1299; year <= 1899; year++) {
        this.map.addLayer(
          {
            "minzoom": 0,
            "layout": {
              "visibility": "none"
            },
            "paint": {
              "fill-color": '#ff0000',
              "fill-opacity": 0.5
            },
            "filter": [
              "!=",
              "color",
              ""
            ],
            "id": "history_aosiman_" + year,
            "source": "aosiman",
            "source-layer": "history_aosiman_" + year,
            "maxzoom": 9,
            "type": "fill"
          }
        )
        this.map.addLayer(
          {
            "minzoom": 0,
            "layout": { "visibility": "none" },
            "paint": { "line-opacity": 0.7, "line-color": "#ff0000", "line-width": 1 },
            "id": "history_aosiman_line" + year,
            "source": "aosiman",
            "source-layer": "history_aosiman_" + year,
            "maxzoom": 9,
            "type": "line"
          }
        )

      }
      this.map.setLayoutProperty("history_aosiman_" + 1299, 'visibility', 'visible')
      this.map.setLayoutProperty("history_aosiman_line" + 1299, 'visibility', 'visible')

    });
    this.map.style.glyphManager.localGlyphMode = 0;
  }
}
</script>
<style scoped>
.el-container {
  height: 100%;
  background-color: #f1e9d2;
}

p {
  text-align: left;
}

.el-header {
  padding: 0;
  background-color: #f1e9d2;
}

.el-card {
  background-color: #f1e9d2;
}

#ttmap {
  width: 100%;
  height: 90%;
}

#tmap {
  width: 100%;
  height: 100%;
}
</style>
