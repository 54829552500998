<template>
  <el-container>
    <el-header>
          <navBar cIndex="/donate"></navBar>
    </el-header>
    <el-main>
      <p>Thank you for visiting my non-profit mapping website! This project is independently supported by the author（@daoyuan997）, with the goal of providing users with a better mapping service. The immense effort required to maintain such a large  mapping website and to develop new features for the project can only be sustained through the generous financial support of our sponsors.</p>

      <h3>【Purpose of Donations】</h3>
      <p>Your contributions will be used for the following purposes:
      Server Maintenance: Ensuring the website runs smoothly and provides a fast browsing experience.
      Technical Upgrades: Continuously improving website features to meet user needs.
      Content Updates: Regularly updating map data to ensure accuracy and timeliness.</p>

      <h3>【Making the Product Better】</h3>
     <p> Every donation directly impacts the quality of our product. Your support will help us:
      Enhance user experience
      Add more useful features
      Expand the coverage of map data</p>

      <p>Thank you for your generous support! Together, we can make this mapping product even better!</p>
    </el-main>
    <el-footer>Designed by daoyuan997 <el-link type="info"
        href="mailto://daoyuan997@gmail.com">feedback:daoyuan997@gmail.com</el-link></el-footer>
  </el-container>
</template>
<script>
import navBar from '../components/NavBar.vue'
export default {
  data () {
    return {}
  },
  components: {
    navBar
  }
}
</script>
<style scoped>
.el-container {
  height: 100%;
  background-color: #f1e9d2;
}
.el-header {
  padding: 0;
  background-color: #f1e9d2;
}
</style>
