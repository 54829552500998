<template>
  <div id="mapp">
    <div class="tool-box">
      <div title="subject" class="tool-box-item"><button class="tool-box-item-btn" @click="handleCommand('ottoman')"><i class="el-icon-place"></i></button></div>
      <div title="compare" class="tool-box-item"><button class="tool-box-item-btn" @click="handleCommand('map-cmp')"><i class="fa fa-columns"></i></button></div>
    </div>
    <div id="map"></div>
    <virtual-ruler id="rule" ref="ruler" top-border :height="50" :min=-3200 :max="1900" :default-value="currentYear"
      :gap="10" :value-gap="1" @change="ruleChange">
    </virtual-ruler>
    <div class="year-input">
      <el-input-number v-model="currentYear" @change="changeYear" :min="minYear" :max="maxYear" :step="step"
        :step-strictly="true" label="currentYear"></el-input-number>
    </div>
    <el-drawer :visible.sync="drawer" :direction="direction" :modal=false custom-class="info-draw">
      <div slot="title"><strong>{{ info.title_en }}</strong></div>
      <p class="info-text">{{ info.text }}</p>
    </el-drawer>
    <div class="search">
      <el-autocomplete class="inline-input" v-model="keyword" :fetch-suggestions="querySearch" placeholder="Roman Empire"
        :trigger-on-focus="false" @select="handleSelect">

        <el-dropdown slot="prepend" size="medium" @command="handleCommand">
          <el-button type="primary">
            <i class="el-icon-s-operation"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="/">Home</el-dropdown-item>
            <el-dropdown-item command="map-cmp">Compare</el-dropdown-item>
            <el-dropdown-item command="3d">3D</el-dropdown-item>
            <el-dropdown-item command="subject">Subject</el-dropdown-item>
            <el-dropdown-item command="donate">Donate</el-dropdown-item>
            <el-dropdown-item command="about">About</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button slot="prepend" icon="el-icon-s-home" @click="gohome"></el-button> -->
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-autocomplete>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import mapboxgl from "mapbox-gl";
import "../assets/mapbox.css";
import { CompassControl, StylesControl } from "mapbox-gl-controls";
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import "mapbox-gl-style-switcher/styles.css";
import 'virtual-ruler/dist/virtual-ruler.css';
import VirtualRuler from 'virtual-ruler'
import { Config } from '../config/config.js'
import ProjectionControl from '../controls/switchProjectControl'
export default {
  data() {
    return {
      zoom: 2,
      minZoom: 0,
      maxZoom: 9,
      map: null,
      drawer: false,
      direction: 'ltr',
      defaultYear: 1400,
      minYear: -3200,
      maxYear: 1900,
      currentYear: 1400,
      style: null,
      info: {},
      timeoutId: null,
      ruletimeoutId: null,
      keyword: '',
      step: 1,
      center: [0, 0],
      selectSearch: null
    };
  },
  components: {
    VirtualRuler,
  },
  methods: {
    gohome() {
      this.$router.push("/")
    },
    handleCommand(com) {
      if (com == '3d') {
        this.map.setProjection('globe')
      } else {
        this.$router.push(com)
      }
    },
    search() {
      console.log('search')
      if (this.selectSearch) {
        let e = parseInt(this.selectSearch['years'])
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
          this.$refs.ruler.setValue(1)
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }

    },
    handleSelect(item) {
      console.log(item);
      this.selectSearch = item
    },
    async querySearch(queryString, cb) {
      let { data } = await this.$http.get(Config.host + '/api/tile/search?q=' + queryString)
      let results = data['data'].map(x => {
        return {
          ...x,
          value: x['nameEn'] + "(" + x['years'] + ")"
        }
      })
      cb(results);
    },

    changeYear(e) {
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
          this.$refs.ruler.setValue(1)
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }, 300);
    },
    ruleChange(e) {
      console.log(e)
      if (this.ruletimeoutId !== null) {
        clearTimeout(this.ruletimeoutId);
      }
      this.ruletimeoutId = setTimeout(() => {
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }, 300);
    },
    format(year) {
      if (year > 0) {
        return 'AD' + year
      } else {
        return 'BC' + (-year)
      }
    },
    async showYear() {
      let year = this.format(this.currentYear)
      if (localStorage.getItem("zoom")) {
        this.zoom = Number(localStorage.getItem("zoom"))
      }
      if (localStorage.getItem("center")) {
        this.center = JSON.parse(localStorage.getItem("center"))
      }

      console.log(Config.host)
      let { data } = await this.$http.get(Config.host + '/api/tile/style/' + year)
      this.style = data
      console.log(data)
      this.map = new mapboxgl.Map({
        container: "map",
        style: data,
        pitchWithRotate: false,
        dragRotate: false,
        zoom: this.zoom,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        center: this.center,
        preserveDrawingBuffer: true
        // controls: this.controls,
        // transformRequest: (url, resourceType) => {
        //   if (url.indexOf('api/styles/style.json') !== -1) {
        //     // localhost 生效，同源时不影响
        //     return {
        //       url: url,
        //       credentials: 'include'
        //     }
        //   }
        // },
      });
      this.map._authenticate = function () { };
      this.map.on("style.load", () => {
        this.map.setFog({});
      });
      this.map.style.glyphManager.localGlyphMode = 0;
      this.map.addControl(new CompassControl(), "top-right");
      const styles = [
        {
          title: "default",
          uri: this.style
        },
        {
          title: "terrain",
          uri: this.style
        },
        {
          title: "river",
          uri: this.style
        }
      ];
      const options = {
        defaultStyle: "default",
        eventListeners: {
          // return true if you want to stop execution
          // onOpen: (event: MouseEvent) => boolean;
          // onSelect: (event: MouseEvent) => boolean;
          onChange: (event, style) => {
            console.log(event);
            console.log(style);
            this.map.setFog({});
            return true;
          }
        }
      };
     // this.map.addControl(new MapboxStyleSwitcherControl(styles, options));
      this.map.addControl(new ProjectionControl());
      const that = this

      this.map.on('load', () => {
        this.map.on('click', 'dls_poi_v1', async (e) => {
          console.log(e);
          e.preventDefault()

          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['dls_poi_v1'] }
          );
          console.log(features)

          if (features.length > 0) {
            let poiId = features[0].properties['poi_id']
            let { data } = await this.$http.get(Config.host + '/api/poi/info?poiId=' + poiId)
            console.log(data)
            new mapboxgl.Popup({ className: 'popup-class' })
              .setLngLat(e.lngLat)
              .setHTML(`
                <div class="pop-poi" style="height: 550px; overflow: hidden;">
                  <div><h3>${features[0].properties['name_en']} (${data['data']['modernNameEn']})</h3></div>
                  <div style="height: 150px;""><img src =" ${data['data']['imgUrl']}" style="width: 100%;height: 100%;object-fit: cover;" /></div>
                  <div style="height: 330px; overflow-y: scroll; padding" >${data['data']['summaryEn']}</div>
          `)
              .setMaxWidth("300px")
              .addTo(this.map);
          }
        });
        this.map.on('mouseenter', 'dls_poi_v1', (e) => {
          console.log(e);
          this.map.getCanvas().style.cursor = 'pointer'
          e.preventDefault()
        });
        this.map.on('mouseout', 'dls_poi_v1', (e) => {
          console.log(e);
          this.map.getCanvas().style.cursor = ''
          e.preventDefault()
        });

        this.map.on('click', async (e) => {
          if (e.defaultPrevented) return;
          console.log(e.lngLat);
          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['country', 'country_top', 'country_comb_con'] }
          );
          let popup = null
          if (features.length > 0) {
            console.log(features)
            if (features.length == 1) {
              this.map.setFilter('country-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
              this.map.setFilter('country-top-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
              this.map.setFilter('country-comb-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
              this.info = features[0].properties
              let { data } = await this.$http.get(Config.host + '/api/territory/info?territoryId=' + this.info.territory_id)
              console.log(data)
              this.info['text'] = data.data['descriptionEn']
              this.drawer = true

            } else {
              if (popup) return
              let html = '<div><ul class="pop-ul">'
              for (let i = 0; i < features.length; i++) {
                html += '<li class="comb_popup" tid=' + features[i].properties['territory_id'] + '>' + features[i].properties['title_en'] + '</li>'
              }
              html += '</ul></div>'
              popup = new mapboxgl.Popup({ closeButton: false }).setLngLat(e.lngLat).setHTML(html).addTo(this.map);
              let lis = document.querySelectorAll(".pop-ul li");
              for (let index = 0; index < lis.length; index++) {
                const li = lis[index];
                li.onclick = async function () {
                  let tid = this.getAttribute('tid')
                  that.map.setFilter('country-highlight', ['==', 'territory_id', tid])
                  that.map.setFilter('country-top-highlight', ['==', 'territory_id', tid])
                  that.map.setFilter('country-comb-highlight', ['==', 'territory_id', tid])
                  popup.remove();
                  let { data } = await that.$http.get(Config.host + '/api/territory/info?territoryId=' + tid)
                  console.log(data)
                  that.info['title_en'] = this.innerHTML
                  that.info['text'] = data.data['descriptionEn']
                  that.drawer = true
                }
              }
            }

          } else {
            this.drawer = false
            this.map.setFilter('country-highlight', ['==', 'territory_id', ''])
            this.map.setFilter('country-top-highlight', ['==', 'territory_id', ''])
            this.map.setFilter('country-comb-highlight', ['==', 'territory_id', ''])
          }

        });
        this.map.on('mouseenter', 'country_top', (e) => {
          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['country_top',] }
          );
          if (features.length > 0) {
            console.log(features)
            this.map.setFilter('country_top_hover', ['==', 'territory_id', features[0].properties['territory_id']])
          }
        });
        this.map.on('mouseleave', 'country_top', () => {
          this.map.setFilter('country_top_hover', ['==', 'territory_id', ''])
        });

        this.map.on('mouseenter', 'country', (e) => {
          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['country',] }
          );
          if (features.length > 0) {
            this.map.setFilter('countryborder_hover', ['==', 'territory_id', features[0].properties['territory_id']])
            this.map.setFilter('country_hover', ['==', 'territory_id', features[0].properties['territory_id']])
          }
        });
        this.map.on('mouseleave', 'country', () => {
          this.map.setFilter('countryborder_hover', ['==', 'territory_id', ''])
          this.map.setFilter('country_hover', ['==', 'territory_id', ''])
        });
        this.map.on('mouseenter', 'country_comb_con', (e) => {
          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['country_comb_con',] }
          );
          if (features.length > 0) {
            this.map.setFilter('country_comb_line_hover', ['==', 'territory_id', features[0].properties['territory_id']])
          }
        });
        this.map.on('mouseleave', 'country_comb_con', () => {
          this.map.setFilter('country_comb_line_hover', ['==', 'territory_id', ''])
        });
        this.map.on('zoomend', () => {
          localStorage.setItem('zoom', this.map.getZoom())
        });
        this.map.on('moveend', () => {
          localStorage.setItem('center', "[" + this.map.getCenter().lng + "," + map.getCenter().lat + "]")
        });

       /* this.map.setLayoutProperty('point-level-5', 'text-allow-overlap', true)
        this.map.setLayoutProperty('point-level-4', 'text-allow-overlap', true)
        this.map.setLayoutProperty('point-level-3', 'text-allow-overlap', true)
        this.map.setLayoutProperty('point-level-2', 'text-allow-overlap', true)
        this.map.setLayoutProperty('point-level-1', 'text-allow-overlap', true)*/

        this.map.setLayoutProperty('point-level-5', 'text-line-height', 0.9)
        this.map.setLayoutProperty('point-level-4', 'text-line-height', 0.9)
        this.map.setLayoutProperty('point-level-3', 'text-line-height', 0.9)
        this.map.setLayoutProperty('point-level-2', 'text-line-height', 0.9)
        this.map.setLayoutProperty('point-level-1', 'text-line-height', 0.9)

        if (this.selectSearch) {
          this.map.setFilter('country-highlight', ['==', 'title_en', this.selectSearch['nameEn']])
          this.map.setFilter('country-top-highlight', ['==', 'title_en', this.selectSearch['nameEn']])
          this.map.setFilter('country-comb-highlight', ['==', 'title_en', this.selectSearch['nameEn']])
          this.map.panTo(JSON.parse(this.selectSearch['coordinates'])['coordinates'])
        }
        window.map = this.map;
      })


    }
  },
  mounted: async function () {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      this.direction = 'btt'
    }
    if (localStorage.getItem("currentYear")) {
      this.currentYear = Number(localStorage.getItem("currentYear"))
    } else {
      this.currentYear = this.defaultYear
    }
    if (localStorage.getItem("zoom")) {
      this.zoom = Number(localStorage.getItem("zoom"))
    }
    if (localStorage.getItem("center")) {
      this.center = JSON.parse(localStorage.getItem("center"))
    }
    this.showYear()
  },
  watch: {
    currentYear(newVal, oldVal) {
      console.log(newVal)
      localStorage.setItem("currentYear", newVal)
      this.$refs.ruler.setValue(newVal)
    }
  }
};
</script>
<style scoped >
::v-deep .el-drawer {
  background-color: #f1e9d2 !important
}

::v-deep .el-drawer__header {
  margin-bottom: 7px;
  background-color: #f1e9d2 !important;
}

#mapp {
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}


#rule {
  position: fixed;
  bottom: 0;
  left: 0;
}

.year-input {
  position: fixed;
  bottom: 50px;
  -webkit-appearance: none;
  left: 50%;
  transform: translateX(-50%);
}

.info-text {
  word-spacing: inherit;
  word-break: auto-phrase;
  text-align: -webkit-match-parent;
  margin: 10px;
  line-height: 30px;
  font-size: 18px;
  background-color: #f1e9d2 !important;
}





.search {
  position: fixed;
  top: 22px;
  left: 10px;
}

.el-button {
  padding: 0 !important;
}

li,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.el-dropdown {
  vertical-align: top;
}
::v-deep .tool-box{
  position: fixed;
  top: 84px;
  right: 0px;
  z-index: 999;
  padding: 2px 2px;
}
.tool-box-item{
  
}
.tool-box-item-btn{
  width: 31px;
  height: 31px;
  margin:  7px 7px 0 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
  background-color: #ffffff;
  cursor: pointer;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);  
  border-radius: 20%;
}
</style>