<template>
  <el-container>
    <el-header>
      <navBar cIndex="/subject"></navBar>
    </el-header>
    <el-main>
      <div class="block">
        <span class="demonstration">The Overland Silk Road</span>
        <el-slider v-model="cYear" :step="1" :min="min" :max="max" :format-tooltip="formatTooltip" @change="changeYear">
        </el-slider>
      </div>
      <div id="ttmap">
        <div id="tmap"></div>
      </div>
    </el-main>
    <el-footer>Designed by daoyuan997 <el-link type="info"
        href="mailto://daoyuan997@gmail.com">feedback:daoyuan997@gmail.com</el-link></el-footer>
  </el-container>
</template>
<script>
/* eslint-disable */
import navBar from '../components/NavBar.vue'
import mapboxgl from "mapbox-gl";
import "../assets/mapbox.css";
// import 'mapbox-gl/dist/mapbox-gl.css'
import "mapbox-gl-style-switcher/styles.css"
import { Config } from '../config/config.js'
export default {
  data() {
    return {
      cYear: 618,
      max: 906,
      min: 618,
      zoom: 2,
      minZoom: 0,
      maxZoom: 9,
      map: null,
    }
  },
  components: {
    navBar
  },
  methods: {
    formatTooltip(val) {
      return 'AD' + val;
    }
    , changeYear(y) {
      console.log(y)
      this.cYear = y
      for (let i = 618; i <= y - 1; i++) {
        this.map.setLayoutProperty("tang_" + i, 'visibility', 'none')
        this.map.setLayoutProperty("tang_line_" + i, 'visibility', 'none')
      }
      for (let i = y + 1; i <= 906; i++) {
        this.map.setLayoutProperty("tang_" + i, 'visibility', 'none')
        this.map.setLayoutProperty("tang_line_" + i, 'visibility', 'none')
      }
      this.map.setLayoutProperty("tang_" + y, 'visibility', 'visible')
      this.map.setLayoutProperty("tang_line_" + y, 'visibility', 'visible')

    }, format(year) {
      if (year > 0) {
        return 'AD' + year
      } else {
        return 'BC' + year
      }
    },
  },
  mounted: async function () {
    let year = this.format(this.currentYear)
    let { data } = await this.$http.get(Config.host + '/api/tile/style/' + 'AD1400')
    this.style = data
    this.map = new mapboxgl.Map({
      container: "tmap",
      style: data,
      pitchWithRotate: false,
      dragRotate: false,
      zoom: this.zoom,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      center: [108, 35],
      preserveDrawingBuffer: true
    });
    this.map._authenticate = function () { };
    this.map.on("style.load", () => {
      this.map.setFog({});
    });
    this.map.style.glyphManager.localGlyphMode = 0;
    this.map.on('load',()=>{
      const geojson = {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            properties: {},
            geometry: {
              coordinates: [
                [108.95, 34.26],
                [94.68, 40.14],
                [89.604492, 40.128491],
                [66.98, 39.64],
                [44.41, 33.33],
                [28.98, 41.01]
              ],
              type: 'LineString'
            }
          }
        ]
      };
      this.map.addSource('line', {
        type: 'geojson',
        data: geojson
      });
      this.map.addLayer({
        type: 'line',
        source: 'line',
        id: 'line-background',
        paint: {
          'line-color': 'red',
          'line-width': 6,
          'line-opacity': 0.4
        }
      });
      this.map.addLayer({
        type: 'line',
        source: 'line',
        id: 'line-dashed',
        paint: {
          'line-color': 'red',
          'line-width': 6,
          'line-dasharray': [0, 4, 3]
        }
      });

      const dashArraySequence = [
        [0, 4, 3],
        [0.5, 4, 2.5],
        [1, 4, 2],
        [1.5, 4, 1.5],
        [2, 4, 1],
        [2.5, 4, 0.5],
        [3, 4, 0],
        [0, 0.5, 3, 3.5],
        [0, 1, 3, 3],
        [0, 1.5, 3, 2.5],
        [0, 2, 3, 2],
        [0, 2.5, 3, 1.5],
        [0, 3, 3, 1],
        [0, 3.5, 3, 0.5]
      ];

      let step = 0;
      let that = this
      function animateDashArray(timestamp) {
        const newStep = parseInt((timestamp / 50) % dashArraySequence.length);
        if (newStep !== step) {
          that.map.setPaintProperty(
            'line-dashed',
            'line-dasharray',
            dashArraySequence[step]
          );
          step = newStep;
        }
        requestAnimationFrame(animateDashArray);
      }
      animateDashArray(0);
    })
    window.map= this.map
  }
}
</script>
<style scoped>
.el-container {
  height: 100%;
  background-color: #f1e9d2;
}

p {
  text-align: left;
}

.el-header {
  padding: 0;
  background-color: #f1e9d2;
}

.el-card {
  background-color: #f1e9d2;
}

#ttmap {
  width: 100%;
  height: 85%;
}

#tmap {
  width: 100%;
  height: 100%;
}
</style>
