<template>
  <div class="home">
    <el-container>
      <el-header>
        <navBar cIndex="/"></navBar>
      </el-header>
      <el-main>
        <div>
          <h1>Historical Atlas Online</h1>
        </div>
        <el-divider></el-divider>
        <el-carousel indicator-position="outside" height="600px" class="img-slider">
          <el-carousel-item v-for="item in sliderImgs" :key="item">
            <el-image :src="item" @click="gomap">
              <div slot="placeholder" class="image-slot">
                loading<span class="dot">...</span>
              </div>
            </el-image>
          </el-carousel-item>
        </el-carousel>
      </el-main>
      <el-footer>Designed by daoyuan997 <el-link type="info"
          href="mailto://daoyuan997@gmail.com">feedback:daoyuan997@gmail.com</el-link></el-footer>
    </el-container>
  </div>
</template>
<script>
// @ is an alias to /src
/* eslint-disable */
import navBar from '../components/NavBar.vue'
export default {
  data() {
    return {
  
      sliderImgs: ['/slider/a1.png', '/slider/b1.png', '/slider/c1.png']
    };
  },
  components: {
    navBar
  },
  methods: {
    gomap() {
      this.$router.push("/map")
    }
  }
}
</script>
<style scoped>
.home {
  height: 100%;
  background-color: #f1e9d2;
}

.el-container {
  height: 100%;
}

.el-header {
  padding: 0;
  background-color: #f1e9d2;
}

.el-main {
  padding: 0;
  background-color: #f1e9d2;
}

/* .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 600px;
    margin: 0;
  }
  .el-carousel__container{
    height: 600px !important;
  }
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  } */
/* .el-header, .el-footer {
    align-self:center;
    margin: 0;
    padding: 0;
  } */
</style>
