<template>
  <div id="mapp">
    <div id="cmp-map">
      <div class="go-back"><button @click="goback"><i class="el-icon-back"></i></button></div>
      <div id="before" class="map"></div>
      <div id="after" class="map"></div>
      <div class="year-input-before">
        <el-input-number v-model="beforeYear" @change="changeBeforeYear" :min="minYear" :max="maxYear"
          label="currentYear"></el-input-number>
      </div>
      <div class="year-input-after">
        <el-input-number v-model="afterYear" @change="changeAfterYear" :min="minYear" :max="maxYear"
          label="currentYear"></el-input-number>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import mapboxgl from "mapbox-gl";
import "../assets/mapbox.css";
// import 'mapbox-gl/dist/mapbox-gl.css'
import "mapbox-gl-style-switcher/styles.css";
import Compare from 'mapbox-gl-compare'
import 'mapbox-gl-compare/dist/mapbox-gl-compare.css'
import { Config } from '../config/config.js'
var syncMove = require('@mapbox/mapbox-gl-sync-move');
mapboxgl.Compare = Compare
export default {
  data() {
    return {
      minYear: -3200,
      maxYear: 1900,
      zoom: 2,
      minZoom: 0,
      maxZoom: 9,
      beforeMap: null,
      afterMap: null,
      beforeYear: 1400,
      afterYear: 1401,
      beforetimeoutId: null,
      aftertimeoutId: null,
    };
  },
  methods: {
    format(year) {
      if (year > 0) {
        return 'AD' + year
      } else {
        return 'BC' + (-year)
      }
    },
    changeBeforeYear(e) {
      if (this.beforetimeoutId !== null) {
        clearTimeout(this.beforetimeoutId);
      }
      this.beforetimeoutId = setTimeout(async () => {
        console.log(e)
        this.beforeYear = e
        await this.showBeforeYear()
        window.map._mapA = this.beforeMap
        syncMove(this.beforeMap,this.afterMap)

        // let map = new mapboxgl.Compare(this.beforeMap, this.afterMap, '#cmp-map', {
        //   // Set this to enable comparing two maps by mouse movement:
        //   // mousemove: true
        //   orientation: 'vertical'
        // });
        // window.map= map
        // window.beforeMap = this.beforeMap;
        // window.afterMap = this.afterMap;
      }, 300);
    },
    changeAfterYear(e) {
      if (this.aftertimeoutId !== null) {
        clearTimeout(this.aftertimeoutId);
      }
      this.aftertimeoutId = setTimeout(async() => {
        console.log(e)
        this.afterYear = e
        await this.showAfterYear()
        window.map._mapB = this.afterMap
        syncMove(this.beforeMap,this.afterMap)
        // window.map= null
        // let map = new mapboxgl.Compare(this.beforeMap, this.afterMap, '#cmp-map', {
        //   // Set this to enable comparing two maps by mouse movement:
        //   // mousemove: true
        //   orientation: 'vertical'
        // });
        // window.map= map
        // window.beforeMap = this.beforeMap;
        // window.afterMap = this.afterMap;
      }, 300);
    },
    async showBeforeYear() {
      let year = this.format(this.beforeYear)
      let { data } = await this.$http.get(Config.host + '/api/tile/style/' + year)
      console.log(data)
      this.beforeMap = new mapboxgl.Map({
        container: "before",
        style: data,
        pitchWithRotate: false,
        dragRotate: false,
        zoom: this.zoom,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        center: [0, 0],
        preserveDrawingBuffer: true
      });
      this.beforeMap._authenticate = function () { };
      this.beforeMap.on("style.load", () => {
        this.beforeMap.setFog({});
      });
      this.beforeMap.style.glyphManager.localGlyphMode = 0;
    },
    async showAfterYear() {
      let year = this.format(this.afterYear)
      let { data } = await this.$http.get(Config.host + '/api/tile/style/' + year)
      console.log(data)
      this.afterMap = new mapboxgl.Map({
        container: "after",
        style: data,
        pitchWithRotate: false,
        dragRotate: false,
        zoom: this.zoom,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        center: [0, 0],
        preserveDrawingBuffer: true
      });
      this.afterMap._authenticate = function () { };
      this.afterMap.on("style.load", () => {
        this.afterMap.setFog({});
      });
      this.afterMap.style.glyphManager.localGlyphMode = 0;
    },
    goback(){
      this.$router.push("map")
    }

  },
  mounted: async function () {
    await this.showBeforeYear()
    await this.showAfterYear()
    let map = new mapboxgl.Compare(this.beforeMap, this.afterMap, '#cmp-map', {
      // Set this to enable comparing two maps by mouse movement:
      // mousemove: true
      orientation: 'vertical'
    });
    window.map = map
    window.beforeMap = this.beforeMap;
    window.afterMap = this.afterMap;
  }
};
</script>
<style scoped>
#mapp {
  width: 100%;
  height: 100%;
}

.map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

.year-input-before {
  width: 50%;
  float: left;
}

.year-input-after {
  width: 50%;
  float: left;
}
.go-back{
  position: fixed;
  z-index: 999;
  top: 15px;
  left: 20px;
}
</style>