<template>
  <div id="mapp">
    <div id="map"></div>
    <virtual-ruler id="rule" ref="ruler" top-border :height="50" :min=-3200 :max="1900" :default-value="currentYear"
      :gap="10" :value-gap="1" @change="ruleChange">
    </virtual-ruler>
    <div class="year-input">
      <el-input-number v-model="currentYear" @change="changeYear" :min="minYear" :max="maxYear" :step="step"
        :step-strictly="true" label="currentYear"></el-input-number>
    </div>
    <el-drawer :visible.sync="drawer" :direction="direction" :modal=false custom-class="info-draw">
      <div slot="title"><strong>{{ info.title_en }}</strong></div>
      <p class="info-text">{{ info.text }}</p>
    </el-drawer>
    <div class="search">
      <el-autocomplete class="inline-input" v-model="keyword" :fetch-suggestions="querySearch" placeholder="ming dynasty"
        :trigger-on-focus="false" @select="handleSelect">

        <el-dropdown slot="prepend" size="medium" @command="handleCommand">
          <el-button type="primary">
            <i class="el-icon-s-operation"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="/">home</el-dropdown-item>
            <el-dropdown-item command="map-cmp">Compare</el-dropdown-item>
            <el-dropdown-item command="3d">3D</el-dropdown-item>
            <el-dropdown-item command="subject">Subject</el-dropdown-item>
            <el-dropdown-item command="donate">Donate</el-dropdown-item>
            <el-dropdown-item command="about">About</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button slot="prepend" icon="el-icon-s-home" @click="gohome"></el-button> -->
        <el-button slot="append" icon="el-icon-search" @click="search"></el-button>
      </el-autocomplete>
    </div>
  </div>
</template>
<script>
/* eslint-disable */
import mapboxgl from "mapbox-gl";
import "../assets/mapbox.css";
import { CompassControl, StylesControl } from "mapbox-gl-controls";
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import "mapbox-gl-style-switcher/styles.css";
import 'virtual-ruler/dist/virtual-ruler.css';
import VirtualRuler from 'virtual-ruler'
import { Config } from '../config/config.js'

import ProjectionControl from '../controls/switchProjectControl'

export default {
  data() {
    return {
      zoom: 2,
      minZoom: 0,
      maxZoom: 9,
      map: null,
      drawer: false,
      direction: 'ltr',
      defaultYear: 1400,
      minYear: -3200,
      maxYear: 1900,
      currentYear: -3200,
      style: null,
      info: {},
      timeoutId: null,
      ruletimeoutId: null,
      keyword: '',
      step: 1,
      territorys: [
        { "value": "ming dynasty", "territory_id": "205:1574411435000" },
        { "value": "dddd", "territory_id": "205:1574416802541" },
        { "value": "addf", "territory_id": "205:1574411679287" },
        { "value": "eredeee", "territory_id": "205:1574416665792" },
        { "value": "hi", "territory_id": "205:1574414068654" },
        { "value": "dere", "territory_id": "205:1574413048364" },
        { "value": "hello", "territory_id": "205:1574412121099" },
      ]
    };
  },
  components: {
    VirtualRuler,
  },
  methods: {
    gohome() {
      this.$router.push("/")
    },
    handleCommand(com) {
      if (com == '3d') {
        this.map.setProjection('globe')
      } else {
        this.$router.push(com)
      }
    },
    search() {
      console.log(this.keyword)
      console.log('to planint ...')
    },
    handleSelect(item) {
      console.log(item);
    },
    querySearch(queryString, cb) {
      var territorys = this.territorys;
      var results = queryString ? territorys.filter(this.createFilter(queryString)) : territorys;
      cb(results);
    },
    createFilter(queryString) {
      return (territorys) => {
        return (territorys.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    changeYear(e) {
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
          this.$refs.ruler.setValue(1)
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }, 300);
    },
    ruleChange(e) {
      console.log(e)
      if (this.ruletimeoutId !== null) {
        clearTimeout(this.ruletimeoutId);
      }
      this.ruletimeoutId = setTimeout(() => {
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }, 300);
    },
    format(year) {
      if (year > 0) {
        return 'AD' + year
      } else {
        return 'BC' + (-year)
      }
    },
    async showYear() {
      let year = this.format(this.currentYear)
      console.log(Config.host)
      let { data } = await this.$http.get(Config.host + '/api/tile/style/' + year)
      this.style = data
      let layers = data.layers.filter(item => item.id !== 'point-level-5' && item.id !== "point-level-4" && item.id !== "point-level-3" && item.id !== "point-level-2" && item.id !== "point-level-1")
      layers.push({
        "minzoom": 5,
        "layout": {
          "text-justify": "center",
          "text-offset": [
            0,
            0.5
          ],
          "text-field": "{name_en}",
          "text-padding": 0,
          "text-size": [
            "interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],
            5,
            14,
            8,
            30
          ],
          "text-font": [
            "arialuni"
          ],
          "text-rotate": 45.00,
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-size": 1,
          "text-max-width": [
            "interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],
            2,
            5,
            8,
            10
          ]
        },
        "paint": {
          "text-color": "#000000"
        },
        "id": "point-level-5",
        "source": "dlsgis_his_point",
        "source-layer": "point-level-5",
        "maxzoom": 9,
        "type": "symbol"
      })
      layers.push({
        "minzoom": 4,
        "layout": {
          "text-justify": "center",
          "text-offset": [
            0,
            0.5
          ],
          "text-field": "{name_en}",
          "text-padding": 0,
          "text-size": [
            "interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],
            4,
            14,
            8,
            30
          ],
          "text-font": [
            "arialuni"
          ],
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-size": 1,
          "text-max-width": [
            "interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],
            2,
            5,
            8,
            12
          ]
        },
        "paint": {
          "text-color": "#000000"
        },
        "id": "point-level-4",
        "source": "dlsgis_his_point",
        "source-layer": "point-level-4",
        "maxzoom": 9,
        "type": "symbol"
      })
      layers.push({
        "minzoom": 3,
        "layout": {
          "text-justify": "center",
          "text-offset": [
            0,
            0.5
          ],
          "text-field": "{name_en}",
          "text-padding": 0,
          "text-size": [
            "interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],

            3,
            14,
       
            8,
            30
          ],
          "text-font": [
            "arialuni"
          ],
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-size": 1,
          "text-max-width": [
            "interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],
            2,
            5,
            8,
            14
          ]
        },
        "paint": {
          "text-color": "#000000"
        },
        "id": "point-level-3",
        "source": "dlsgis_his_point",
        "source-layer": "point-level-3",
        "maxzoom": 9,
        "type": "symbol"
      })
      layers.push({
        "minzoom": 2,
        "layout": {
          "text-justify": "center",
          "text-offset": [
            0,
            0.5
          ],
          "text-field": "{name_en}",
          "text-padding": 0,
          "text-size": [
            "interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],
            2,
            14,
            
            8,
            30
          ],
          "text-font": [
            "arialuni"
          ],
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-size": 1,
          "text-max-width": [
            "interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],
            2,
            5,
            8,
            16
          ]
        },
        "paint": {
          "text-color": "#000000"
        },
        "id": "point-level-2",
        "source": "dlsgis_his_point",
        "source-layer": "point-level-2",
        "maxzoom": 9,
        "type": "symbol"
      })
      layers.push({
        "minzoom": 1,
        "layout": {
          "text-justify": "center",
          "text-offset": [
            0,
            0.5
          ],
          "text-field": "{name_en}",
          "text-padding": 0,
          "text-size": [
            "interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],
            2,
            14,
            8,
            30
          ],
          "text-font": [
            "arialuni"
          ],
          "icon-allow-overlap": true,
          "icon-ignore-placement": true,
          "icon-size": 1,
          "text-max-width": [
            "interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],
            2,
            5,
            8,
            20
          ]
         
        },
        "paint": {
          "text-color": "#000000",
          "text-halo-color": "#000000"
         
        },
        "id": "point-level-1",
        "source": "dlsgis_his_point",
        "source-layer": "point-level-1",
        "maxzoom": 9,
        "type": "symbol"
      })
      data.sprite = "https://timelinegeo.com/mapres/sprite_v2"
      data.layers = layers
      console.log(data)
      this.map = new mapboxgl.Map({
        container: "map",
        style: data,
        pitchWithRotate: false,
        dragRotate: false,
        zoom: this.zoom,
        minZoom: this.minZoom,
        maxZoom: this.maxZoom,
        center: [0, 0],
        preserveDrawingBuffer: true
        // controls: this.controls,
        // transformRequest: (url, resourceType) => {
        //   if (url.indexOf('api/styles/style.json') !== -1) {
        //     // localhost 生效，同源时不影响
        //     return {
        //       url: url,
        //       credentials: 'include'
        //     }
        //   }
        // },
      });
      this.map._authenticate = function () { };
      this.map.on("style.load", () => {
        this.map.setFog({});
      });
      this.map.style.glyphManager.localGlyphMode = 0;
      this.map.addControl(new CompassControl(), "top-right");
      this.map.addControl(new ProjectionControl());
      const that = this
      this.map.on('load', () => {
        this.map.on('click', 'dls_poi_v1', async (e) => {
          console.log(e);
          e.preventDefault()
          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['dls_poi_v1'] }
          );
          console.log(features)

          if (features.length > 0) {
            let poiId = features[0].properties['poi_id']
            let { data } = await this.$http.get(Config.host + '/api/poi/info?poiId=' + poiId)
            console.log(data)
            const popup = new mapboxgl.Popup({ className: 'popup-class' })
              .setLngLat(e.lngLat)
              .setHTML(`
                <div style="height: 500px;overflow-y:auto;">
                  <div><h3>${data['data']['modernNameEn']}</h3></div>
                  <div ><img src =" ${data['data']['imgUrl']}" style="width: 100%;height: 100%;object-fit: cover;" /></div>
                  <div style=" height:500px">${data['data']['summaryEn']}</div>
          `)
              .setMaxWidth("300px")
              .addTo(this.map);
          }
        });
        this.map.on('mouseenter', 'dls_poi_v1', (e) => {
          console.log(e);
          this.map.getCanvas().style.cursor = 'pointer'
          e.preventDefault()
        });
        this.map.on('mouseout', 'dls_poi_v1', (e) => {
          console.log(e);
          this.map.getCanvas().style.cursor = ''
          e.preventDefault()
        });
        this.map.on('click', async (e) => {
          if (e.defaultPrevented) return;
          console.log(e.lngLat);
          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['country', 'country_comb_con', 'country_top'] }
          );
          if (features.length > 0) {
            console.log(features)
            if (features.length == 1) {
              this.map.setFilter('country-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
              this.map.setFilter('country-top-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
              this.map.setFilter('country-comb-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
              this.info = features[0].properties
              let { data } = await this.$http.get(Config.host + '/api/territory/info?territoryId=' + this.info.territory_id)
              console.log(data)
              this.info['text'] = data.data['descriptionEn']
              this.drawer = true

            } else {
              let html = '<div><ul class="pop-ul">'
              for (let i = 0; i < features.length; i++) {
                html += '<li class="comb_popup" tid=' + features[i].properties['territory_id'] + '>' + features[i].properties['title_en'] + '</li>'
              }
              html += '</ul></div>'
              const popup = new mapboxgl.Popup({ closeButton: false }).setLngLat(e.lngLat).setHTML(html).addTo(this.map);
              let lis = document.querySelectorAll(".pop-ul li");
              for (let index = 0; index < lis.length; index++) {
                const li = lis[index];
                li.onclick = async function () {
                  let tid = this.getAttribute('tid')
                  that.map.setFilter('country-highlight', ['==', 'territory_id', tid])
                  that.map.setFilter('country-top-highlight', ['==', 'territory_id', tid])
                  that.map.setFilter('country-comb-highlight', ['==', 'territory_id', tid])
                  popup.remove();
                  let { data } = await that.$http.get(Config.host + '/api/territory/info?territoryId=' + tid)
                  console.log(data)
                  that.info['title_en'] = this.innerHTML
                  that.info['text'] = data.data['descriptionEn']
                  that.drawer = true
                }
              }
            }

          } else {
            this.drawer = false
            this.map.setFilter('country-highlight', ['==', 'territory_id', ''])
            this.map.setFilter('country-top-highlight', ['==', 'territory_id', ''])
            this.map.setFilter('country-comb-highlight', ['==', 'territory_id', ''])
          }

        });
        this.map.on('mouseenter', 'country_top', (e) => {
          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['country_top',] }
          );
          if (features.length > 0) {
            console.log(features)
            this.map.setFilter('country_top_hover', ['==', 'territory_id', features[0].properties['territory_id']])
          }
        });
        this.map.on('mouseleave', 'country_top', () => {
          this.map.setFilter('country_top_hover', ['==', 'territory_id', ''])
        });

        this.map.on('mouseenter', 'country', (e) => {
          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['country',] }
          );
          if (features.length > 0) {
            this.map.setFilter('countryborder_hover', ['==', 'territory_id', features[0].properties['territory_id']])
            this.map.setFilter('country_hover', ['==', 'territory_id', features[0].properties['territory_id']])
          }
        });
        this.map.on('mouseleave', 'country', () => {
          this.map.setFilter('countryborder_hover', ['==', 'territory_id', ''])
          this.map.setFilter('country_hover', ['==', 'territory_id', ''])
        });
        this.map.on('mouseenter', 'country_comb_con', (e) => {
          const features = this.map.queryRenderedFeatures(
            e.point,
            { layers: ['country_comb_con',] }
          );
          if (features.length > 0) {
            this.map.setFilter('country_comb_line_hover', ['==', 'territory_id', features[0].properties['territory_id']])
          }
        });
        this.map.on('mouseleave', 'country_comb_con', () => {
          this.map.setFilter('country_comb_line_hover', ['==', 'territory_id', ''])
        });

        this.map.setLayoutProperty('dls_poi_v1', 'icon-image',  [
            'match',
            ['get', 'category'],
            '一级城市', 'city1', // 类型是 fruit，显示 fruit-icon
            '二级城市', 'city2', // 类型是 fruit，显示 fruit-icon
            '三级城市', 'city3', // 类型是 fruit，显示 fruit-icon
            '首都', 'capital', // 类型是 fruit，显示 fruit-icon
            '战场', 'war', // 类型是 vegetable vegetable-icon
            '古迹', 'monument',
            '定居点', 'place',
            '事件发生地', 'event',
            '山峰', 'mountain',
            '金字塔', 'pyramid',
            '要塞', 'fort',
            'city3' 
          ])
        this.map.setLayoutProperty('dls_poi_v1', 'icon-size', ["interpolate",
            [
              "linear"
            ],
            [
              "zoom"
            ],
            3,0.6,
            4,0.6,
            5,0.8,
            8,1
            ])
        this.map.setLayoutProperty('dls_poi_v1', 'icon-offset', [0,-10])
        window.map = this.map;
      })

    }
  },
  mounted: async function () {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      this.direction = 'btt'
    }
    if (localStorage.getItem("currentYear")) {
      this.currentYear = Number(localStorage.getItem("currentYear"))
    } else {
      this.currentYear = this.defaultYear
    }
    this.showYear()
  },
  watch: {
    currentYear(newVal, oldVal) {
      console.log(newVal)
      this.$refs.ruler.setValue(newVal)
    }
  }
};
</script>
<style scoped >
::v-deep .el-drawer {
  background-color: #f1e9d2 !important
}

::v-deep .el-drawer__header {
  margin-bottom: 7px;
  background-color: #f1e9d2 !important;
}

#mapp {
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}


#rule {
  position: fixed;
  bottom: 0;
  left: 0;
}

.year-input {
  position: fixed;
  bottom: 50px;
  -webkit-appearance: none;
  left: 50%;
  transform: translateX(-50%);
}

.info-text {
  word-spacing: inherit;
  word-break: auto-phrase;
  text-align: -webkit-match-parent;
  margin: 10px;
  line-height: 30px;
  font-size: 18px;
  background-color: #f1e9d2 !important;
}





.search {
  position: fixed;
  top: 22px;
  left: 10px;
}

.el-button {
  padding: 0 !important;
}

li,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.el-dropdown {
  vertical-align: top;
}
</style>