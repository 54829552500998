<template>
  <div id="mapp">
    <div id="map"></div>
    <virtual-ruler id="rule" ref="ruler" top-border :height="50" :min=-3200 :max="1900" :default-value="currentYear"
      :gap="10" :value-gap="1" @change="ruleChange">
    </virtual-ruler>
    <div class="year-input">
      <el-input-number v-model="currentYear" @change="changeYear" :min="minYear" :max="maxYear" :step="step"
        :step-strictly="true" label="currentYear"></el-input-number>
    </div>
    <el-drawer :visible.sync="drawer" :direction="direction" :modal=false custom-class="info-draw">
      <div slot="title"><strong>{{ info.title_en }}</strong></div>
      <p class="info-text">{{ info.text }}</p>
    </el-drawer>
  </div>
</template>
<script>
/* eslint-disable */
import mapboxgl from "mapbox-gl";
import "../assets/mapbox.css";
import { CompassControl, StylesControl } from "mapbox-gl-controls";
import { MapboxStyleSwitcherControl } from "mapbox-gl-style-switcher";
import "mapbox-gl-style-switcher/styles.css";
import 'virtual-ruler/dist/virtual-ruler.css';
import VirtualRuler from 'virtual-ruler'
import { Config } from '../config/config.js'

import ProjectionControl from '../controls/switchProjectControl'

export default {
  data() {
    return {
      zoom: 2,
      minZoom: 0,
      maxZoom: 9,
      map: null,
      drawer: false,
      direction: 'ltr',
      defaultYear: 1400,
      minYear: -3200,
      maxYear: 1900,
      currentYear: -3200,
      style: {
        "layers": [
          {
            "id": "background",
            "layout": {
              "visibility": "visible"
            },
            "type": "background",
            "maxzoom": 9,
            "minzoom": 0,
            "paint": {
              "background-color": "#538cb0"
            }
          },
          {
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "line-width": 1,
              "line-color": "red",
              "line-opacity": 1,
        
            },
            "id": "lonlat-line",
            "maxzoom": 9,
            "minzoom": 2,
            "type": "line",
            "source": "dlsgis_his_regime_water",
            "source-layer": "lonlatline"
          },
          {
            "minzoom": 0,
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "fill-color": "#FFFDF7"
            },
            "id": "ADM_CON_S_NOW",
            "source": "dlsgis_base",
            "source-layer": "7FD77FBD27481594127457717",
            "maxzoom": 9,
            "type": "fill"
          },
          {
            "minzoom": 0,
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "line-color": {
                "property": "bordercolor",
                "type": "identity"
              },
              "line-opacity": {
                "property": "borderopacity",
                "type": "identity"
              },
              "line-width": 1
            },
            "id": "border_line_0_8",
            "source": "dlsgis_base",
            "source-layer": "7FD77FBE61281594127457753",
            "maxzoom": 8,
            "type": "line"
          },
          {
            "layout": {
              "visibility": "visible"
            },
            "maxzoom": 9,
            "paint": {
              "fill-color": {
                "property": "color",
                "type": "identity"
              },
              "fill-opacity": {
                "property": "coloropacity",
                "type": "identity"
              }
            },
            "id": "country",
            "source": "dlsgis_his_regime",
            "type": "fill",
            "minzoom": 0,
            "source-layer": "history_country_101001_BC3200"
          },
          {
            "layout": {
              "visibility": "visible"
            },
            "maxzoom": 9,
            "paint": {
              "fill-color": {
                "property": "color",
                "type": "identity"
              },
              "fill-opacity": {
                "property": "colorhoveropacity",
                "type": "identity"
              }
            },
            "filter": [
              "==",
              "oid",
              ""
            ],
            "id": "country_hover",
            "source": "dlsgis_his_regime",
            "type": "fill",
            "minzoom": 0,
            "source-layer": "history_country_101001_BC3200"
          },

          {
            "minzoom": 0,
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "fill-color": {
                "property": "color",
                "type": "identity"
              },
              "fill-opacity": {
                "property": "coloropacity",
                "type": "identity"
              }
            },
            "id": "country_comb_con",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101002_BC3200",
            "maxzoom": 9,
            "type": "fill"
          },
          {
            "minzoom": 0,
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "fill-color": {
                "property": "color",
                "type": "identity"
              },
              "fill-opacity": {
                "property": "colorhoveropacity",
                "type": "identity"
              }
            },
            "filter": [
              "==",
              "oid",
              ""
            ],
            "id": "country_comb_hover",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101002_BC3200",
            "maxzoom": 9,
            "type": "fill"
          },

          {
            "layout": {
              "visibility": "visible"
            },
            "maxzoom": 9,
            "paint": {
              "fill-color": {
                "property": "color",
                "type": "identity"
              },
              "fill-opacity": {
                "property": "coloropacity",
                "type": "identity"
              }
            },
            "id": "country_top",
            "source": "dlsgis_his_regime",
            "type": "fill",
            "minzoom": 0,
            "source-layer": "history_country_101003_BC3200"
          },
          {
            "layout": {
              "visibility": "visible"
            },
            "maxzoom": 9,
            "paint": {
              "fill-color": {
                "property": "color",
                "type": "identity"
              },
              "fill-opacity": {
                "property": "colorhoveropacity",
                "type": "identity"
              }
            },
            "filter": [
              "==",
              "oid",
              ""
            ],
            "id": "country_top_hover",
            "source": "dlsgis_his_regime",
            "type": "fill",
            "minzoom": 0,
            "source-layer": "history_country_101003_BC3200"
          },

          {
            "id": "country-highlight",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101001_BC3200",
            "type": "fill",
            "layout": {
              "visibility": "visible"
            },
            "maxzoom": 9,
            "minzoom": 0,
            "paint": {
              "fill-color": "#ff3333",
              "fill-opacity": 0.5
            },
            "filter": [
              "==",
              "oid",
              ""
            ]
          },
          {
            "id": "country-top-highlight",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101003_BC3200",
            "type": "fill",
            "layout": {
              "visibility": "visible"
            },
            "maxzoom": 9,
            "minzoom": 0,
            "paint": {
              "fill-color": "#ff3333",
              "fill-opacity": 0.5
            },
            "filter": [
              "==",
              "oid",
              ""
            ]
          },
          {
            "id": "country-comb-highlight",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101002_BC3200",
            "type": "fill",
            "layout": {
              "visibility": "visible"
            },
            "maxzoom": 9,
            "minzoom": 0,
            "paint": {
              "fill-color": "#ff3333",
              "fill-opacity": 0.5
            },
            "filter": [
              "==",
              "oid",
              ""
            ]
          },

          {
            "layout": {
              "visibility": "visible"
            },
            "maxzoom": 9,
            "paint": {
              "fill-color": "#538cb0"
            },
            "id": "lake",
            "source": "dlsgis_base",
            "type": "fill",
            "minzoom": 0,
            "source-layer": "7FD77FBDEC881594127457746"
          },
          {
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "fill-color": "#538cb0"
            },
            "id": "water-polygon",
            "maxzoom": 9,
            "minzoom": 3,
            "type": "fill",
            "source": "dlsgis_his_regime_water",
            "source-layer": "world_water_p"
          },
          {
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "line-width": 1,
              "line-color": "#538cb0"
            },
            "id": "water-line",
            "maxzoom": 9,
            "minzoom": 3,
            "type": "line",
            "source": "dlsgis_his_regime_water",
            "source-layer": "world_water_l"
          },
          {
            "source": "dlsgis_his_terrain",
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "raster-opacity": 0.4
            },
            "id": "ahterrain",
            "type": "raster",
            "maxzoom": 10,
            "minzoom": 3
          },
          
          {
            "minzoom": 0,
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "line-opacity": 0.7,
              "line-color": "#817391",
              "line-width": [
                "interpolate",
                [
                  "linear"
                ],
                [
                  "zoom"
                ],
                2,
                0.5,
                4,
                1,
                6,
                4,
                8,
                6,
                9,
                10
              ]
            },
            "id": "countryborder",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101001_BC3200",
            "maxzoom": 9,
            "type": "line"
          },
          {
            "minzoom": 0,
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "line-color": {
                "property": "borderhovercolor",
                "type": "identity"
              },
              "line-opacity": {
                "property": "borderhoveropacity",
                "type": "identity"
              },
              "line-width": 2
            },
            "filter": [
              "==",
              "oid",
              ""
            ],
            "id": "countryborder_hover",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101001_BC3200",
            "maxzoom": 9,
            "type": "line"
          },
          {
            "minzoom": 0,
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "line-color": {
                "property": "bordercolor",
                "type": "identity"
              },
              "line-opacity": {
                "property": "borderopacity",
                "type": "identity"
              },
              "line-width": [
                "interpolate",
                [
                  "linear"
                ],
                [
                  "zoom"
                ],
                2,
                0.5,
                4,
                1,
                6,
                4,
                8,
                6,
                9,
                10
              ]
            },
            "id": "country_comb",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101002_BC3200",
            "maxzoom": 9,
            "type": "line"
          },
          {
            "minzoom": 0,
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "line-color": {
                "property": "borderhovercolor",
                "type": "identity"
              },
              "line-opacity": {
                "property": "borderhoveropacity",
                "type": "identity"
              },
              "line-width": 0.5
            },
            "filter": [
              "==",
              "oid",
              ""
            ],
            "id": "country_comb_line_hover",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101002_BC3200",
            "maxzoom": 9,
            "type": "line"
          },
          {
            "minzoom": 0,
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "line-color": {
                "property": "bordercolor",
                "type": "identity"
              },
              "line-opacity": {
                "property": "borderopacity",
                "type": "identity"
              },
              "line-width": {
                "property": "borderwidth",
                "type": "identity"
              }
            },
            "id": "country_top_border",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101003_BC3200",
            "maxzoom": 9,
            "type": "line"
          },
          {
            "minzoom": 0,
            "layout": {
              "visibility": "visible"
            },
            "paint": {
              "line-color": {
                "property": "borderhovercolor",
                "type": "identity"
              },
              "line-opacity": {
                "property": "borderhoveropacity",
                "type": "identity"
              },
              "line-width": 1
            },
            "filter": [
              "==",
              "oid",
              ""
            ],
            "id": "country_top_border_hover",
            "source": "dlsgis_his_regime",
            "source-layer": "history_country_101003_BC3200",
            "maxzoom": 9,
            "type": "line"
          },

          {
            "id": "POI_SEA_P_NOW",
            "source": "dlsgis_base",
            "type": "symbol",
            "layout": {
              "text-allow-overlap": true,
              "text-ignore-placement": true,
              "icon-image": "{ICON}",
              "icon-allow-overlap": true,
              "visibility": "visible",
              "symbol-placement": "point"
            },
            "source-layer": "7F579D2590D01597484435758"
          },
          {
            "layout": {
              "text-size": [
                "interpolate",
                [
                  "linear"
                ],
                [
                  "zoom"
                ],
                2,
                10,
                4,
                10,
                6,
                10,
                8,
                30,
                9,
                30
              ],
              "text-font": [
                "FZKTK"
              ],
              "text-padding": 2,
              "text-field": "{name_en}",
              "icon-size": 1,
              "text-justify": "center",
              "icon-image": "admin-1",
              "icon-allow-overlap": false,
              "icon-ignore-placement": false,
              "text-offset": [
                0,
                1.2
              ],
              "text-max-width": 7
            },
            "paint": {
              "text-color": "#000000"
            },
            "id": "textpoint_city",
            "maxzoom": 9,
            "minzoom": 4,
            "type": "symbol",
            "source": "dlsgis_his_regime_city",
            "source-layer": "city-layer"
          },
          {
            "layout": {
              "text-size": [
                "interpolate",
                [
                  "linear"
                ],
                [
                  "zoom"
                ],
                6,
                10,
                8,
                30,
                9,
                30
              ],
              "text-font": [
                "STHeiti-Regular"
              ],
              "text-padding": 2,
              "text-field": "{name}",
              "icon-size": 1,
              "text-justify": "center",
              "text-offset": [
                3.2,
                -0.5
              ],
              "text-max-width": 7
            },
            "paint": {
              "text-color": "#000000",
              "text-opacity": 0.3
            },
            "id": "textpoint_lonlat",
            "maxzoom": 9,
            "minzoom": 4,
            "type": "symbol",
            "source": "dlsgis_his_regime_lonlat",
            "source-layer": "lonlat-point"
          },
          {
            "minzoom": 4,
            "layout": {
              "text-justify": "center",
              "text-offset": [
                0,
                0.5
              ],
              "text-field": "{name_en}",
              "text-padding": 0,
              "text-size": [
                "interpolate",
                [
                  "linear"
                ],
                [
                  "zoom"
                ],
                2,
                10,
                4,
                20,
                6,
                30,
                8,
                60,
                9,
                90
              ],
              "text-rotate": {
                "property": "text_rotate",
                "type": "identity"
              },
              "text-font": [
                "FZKTK"
              ],
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "icon-size": 1,
              "text-max-width": 7
            },
            "paint": {
              "text-color": "#000000"
            },
            "id": "point-level-5",
            "source": "dlsgis_his_point",
            "source-layer": "point-level-5",
            "maxzoom": 9,
            "type": "symbol"
          },
          {
            "minzoom": 4,
            "layout": {
              "text-justify": "center",
              "text-offset": [
                0,
                0.5
              ],
              "text-field": "{name_en}",
              "text-padding": 0,
              "text-size": [
                "interpolate",
                [
                  "linear"
                ],
                [
                  "zoom"
                ],
                2,
                10,
                4,
                14,
                6,
                30,
                8,
                60,
                9,
                90
              ],
              "text-rotate": {
                "property": "text_rotate",
                "type": "identity"
              },
              "text-font": [
                "FZKTK"
              ],
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "icon-size": 1,
              "text-max-width": 7
            },
            "paint": {
              "text-color": "#000000"
            },
            "id": "point-level-4",
            "source": "dlsgis_his_point",
            "source-layer": "point-level-4",
            "maxzoom": 9,
            "type": "symbol"
          },
          {
            "minzoom": 3,
            "layout": {
              "text-justify": "center",
              "text-offset": [
                0,
                0.5
              ],
              "text-field": "{name_en}",
              "text-padding": 0,
              "text-size": [
                "interpolate",
                [
                  "linear"
                ],
                [
                  "zoom"
                ],
                2,
                10,
                4,
                16,
                6,
                30,
                8,
                60,
                9,
                90
              ],
              "text-rotate": {
                "property": "text_rotate",
                "type": "identity"
              },
              "text-font": [
                "FZKTK"
              ],
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "icon-size": 1,
              "text-max-width": 7
            },
            "paint": {
              "text-color": "#000000"
            },
            "id": "point-level-3",
            "source": "dlsgis_his_point",
            "source-layer": "point-level-3",
            "maxzoom": 9,
            "type": "symbol"
          },
          {
            "minzoom": 2,
            "layout": {
              "text-justify": "center",
              "text-offset": [
                0,
                0.5
              ],
              "text-field": "{name_en}",
              "text-padding": 0,
              "text-size": [
                "interpolate",
                [
                  "linear"
                ],
                [
                  "zoom"
                ],
                2,
                16,
                4,
                20,
                6,
                40,
                8,
                80,
                9,
                90
              ],
              "text-rotate": {
                "property": "text_rotate",
                "type": "identity"
              },
              "text-font": [
                "FZKTK"
              ],
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "icon-size": 1,
              "text-max-width": 7
            },
            "paint": {
              "text-color": "#000000"
            },
            "id": "point-level-2",
            "source": "dlsgis_his_point",
            "source-layer": "point-level-2",
            "maxzoom": 9,
            "type": "symbol"
          }, {
            "minzoom": 1,
            "layout": {
              "text-justify": "center",
              "text-offset": [
                0,
                0.5
              ],
              "text-field": "{name_en}",
              "text-padding": 0,
              "text-size": [
                "interpolate",
                [
                  "linear"
                ],
                [
                  "zoom"
                ],
                2,
                18,
                4,
                22,
                6,
                54,
                8,
                84,
                9,
                94
              ],
              "text-rotate": {
                "property": "text_rotate",
                "type": "identity"
              },
              "text-font": [
                "FZKTK"
              ],
              "icon-allow-overlap": true,
              "icon-ignore-placement": true,
              "icon-size": 1,
              "text-max-width": 7
            },
            "paint": {
              "text-color": "#000000"
            },
            "id": "point-level-1",
            "source": "dlsgis_his_point",
            "source-layer": "point-level-1",
            "maxzoom": 9,
            "type": "symbol"
          },
          
        ],
        "center": [
          8.542,
          47.372
        ],
        "sprite": "https://timelinegeo.com/mapres/sprite",
        "zoom": 2,
        "id": "dlsgis-his-regime-BC3200",
        "pitch": 0,
        "name": "DLSGIS His Regime BC3200",
        "version": 8,
        "glyphs": "https://timelinegeo.com/mapres/fonts/{fontstack}/{range}.pbf",
        "bearing": 0,
        "sources": {
          "dlsgis_base": {
            "tiles": [
              "https://timelinegeo.com/tile/data/dlsgis-base/{z}/{x}/{y}.pbf"
            ],
            "type": "vector"
          },
          "dlsgis_his_regime": {
            "tiles": [
              "https://timelinegeo.com/tile30/data/BC3200/{z}/{x}/{y}.pbf"
            ],
            "type": "vector"
          },
          "dlsgis_his_regime_city": {
            "type": "vector",
            "tiles": [
              "https://timelinegeo.com/api/tile/city/BC3200/{z}/{x}/{y}.pbf"
            ]
          },
          "dlsgis_his_terrain": {
            "type": "raster",
            "tileSize": 256,
            "tiles": [
              "https://timelinegeo.com/mapres/terrain/{z}/{x}_{y}.png"
            ]
          },
          "dlsgis_his_regime_water": {
            "type": "vector",
            "tiles": [
              "https://timelinegeo.com/tile/data/water/{z}/{x}_{y}.pbf"
            ]
          },
          "dlsgis_his_regime_lonlat": {
            "type": "vector",
            "tiles": [
              "https://timelinegeo.com/api/tile/lonlat/{z}/{x}/{y}.pbf"
            ]
          },
          "texture": {
            "type": "raster",
            "tileSize": 256,
            "tiles": [
              "https://timelinegeo.com/v-2/texture/{z}/{x}_{y}.png"
            ]
          },
          "dlsgis_his_point": {
            "type": "vector",
            "tiles": [
              "https://timelinegeo.com/api/tile/point/BC3200/{z}/{x}/{y}.pbf"
            ]
          }
        }
      },
      info: {},
      timeoutId: null,
      ruletimeoutId: null,
      keyword: '',
      step: 1
    };
  },
  components: {
    VirtualRuler,
  },
  methods: {
    changeYear(e) {
      if (this.timeoutId !== null) {
        clearTimeout(this.timeoutId);
      }
      this.timeoutId = setTimeout(() => {
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
          this.$refs.ruler.setValue(1)
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }, 300);
    },
    ruleChange(e) {
      console.log(e)
      if (this.ruletimeoutId !== null) {
        clearTimeout(this.ruletimeoutId);
      }
      this.ruletimeoutId = setTimeout(() => {
        if (e == 0) {
          this.currentYear = 1
          this.step = 1
          this.showYear()
        } else if (e >= -3200 && e < -2000) {
          this.currentYear = e
          this.step = 50
          this.showYear()
        }
        else if (e >= -2000 && e < -1000) {
          this.currentYear = e
          this.step = 10
          this.showYear()
        }
        else {
          this.currentYear = e
          this.step = 1
          this.showYear()
        }
      }, 300);
    },
    format(year) {
      if (year > 0) {
        return 'AD' + year
      } else {
        return 'BC' + (-year)
      }
    },
    getTileServer(intYear) {
        if (intYear>=1400 && intYear<=1499){
            return "tile";
        }
        if (intYear>=1500 && intYear<=1599){
            return "tile2";
        }
        if (intYear>=1600 && intYear<=1699){
            return "tile3";
        }
        if (intYear>=1700 && intYear<=1799){
            return "tile4";
        }
        if (intYear>=1800 && intYear<=1900){
            return "tile5";
        }
        if (intYear>=1300 && intYear<=1399){
            return "tile6";
        }
        if (intYear>=1200 && intYear<=1299){
            return "tile7";
        }
        if (intYear>=1100 && intYear<=1199){
            return "tile8";
        }
        if (intYear>=1000 && intYear<=1099){
            return "tile9";
        }
        if (intYear>=900 && intYear<=999){
            return "tile10";
        }
        if (intYear>=800 && intYear<=899){
            return "tile11";
        }
        if (intYear>=700 && intYear<=799){
            return "tile12";
        }
        if (intYear>=600 && intYear<=699){
            return "tile13";
        }
        if (intYear>=500 && intYear<=599){
            return "tile14";
        }
        if (intYear>=400 && intYear<=499){
            return "tile15";
        }
        if (intYear>=300 && intYear<=399){
            return "tile16";
        }
        if (intYear>=200 && intYear<=299){
            return "tile17";
        }
        if (intYear>=100 && intYear<=199){
            return "tile18";
        }
        if (intYear>=1 && intYear<=99){
            return "tile19";
        }
        if (intYear>=-100 && intYear<=-1){
            return "tile20";
        }
        if (intYear>=-200 && intYear<=-101){
            return "tile21";
        }
        if (intYear>=-300 && intYear<=-201){
            return "tile22";
        }
        if (intYear>=-400 && intYear<=-301){
            return "tile23";
        }
        if (intYear>=-500 && intYear<=-401){
            return "tile24";
        }
        if (intYear>=-600 && intYear<=-501){
            return "tile25";
        }
        if (intYear>=-700 && intYear<=-601){
            return "tile26";
        }
        if (intYear>=-800 && intYear<=-701){
            return "tile27";
        }
        if (intYear>=-900 && intYear<=-801){
            return "tile28";
        }
        if (intYear>=-1000 && intYear<=-901){
            return "tile29";
        }
        if (intYear>=-3200 && intYear<=-1001){
            return "tile30";
        }
        return "tile";
    },

    async showYear() {
      let year = this.format(this.currentYear)
      if (this.map.getLayer('country')) this.map.removeLayer('country');
      if (this.map.getLayer('country_hover')) this.map.removeLayer('country_hover');
      if (this.map.getLayer('country_comb_con')) this.map.removeLayer('country_comb_con');
      if (this.map.getLayer('country_comb_hover')) this.map.removeLayer('country_comb_hover');
      if (this.map.getLayer('country_top')) this.map.removeLayer('country_top');
      if (this.map.getLayer('country_top_hover')) this.map.removeLayer('country_top_hover');

      if (this.map.getLayer('country-highlight')) this.map.removeLayer('country-highlight');
      if (this.map.getLayer('country-top-highlight')) this.map.removeLayer('country-top-highlight');
      if (this.map.getLayer('country-comb-highlight')) this.map.removeLayer('country-comb-highlight');

      if (this.map.getLayer('countryborder')) this.map.removeLayer('countryborder');
      if (this.map.getLayer('countryborder_hover')) this.map.removeLayer('countryborder_hover');
      if (this.map.getLayer('country_comb')) this.map.removeLayer('country_comb');
      if (this.map.getLayer('country_comb_line_hover')) this.map.removeLayer('country_comb_line_hover');

      if (this.map.getLayer('country_top_border')) this.map.removeLayer('country_top_border');
      if (this.map.getLayer('country_top_border_hover')) this.map.removeLayer('country_top_border_hover');

      if (this.map.getLayer('point-level-5')) this.map.removeLayer('point-level-5');
      if (this.map.getLayer('point-level-4')) this.map.removeLayer('point-level-4');
      if (this.map.getLayer('point-level-3')) this.map.removeLayer('point-level-3');
      if (this.map.getLayer('point-level-2')) this.map.removeLayer('point-level-2');
      if (this.map.getLayer('point-level-1')) this.map.removeLayer('point-level-1');

      if (this.map.getSource('dlsgis_his_regime')) this.map.removeSource('dlsgis_his_regime');

      this.map.addSource('dlsgis_his_regime',{
          "tiles": [
            "https://timelinegeo.com/"+this.getTileServer(this.currentYear)+"/data/" + year + "/{z}/{x}/{y}.pbf"
          ],
          "type": "vector"
      })
      this.map.addLayer({
            "layout": {
              "visibility": "visible"
            },
            "maxzoom": 9,
            "paint": {
              "fill-color": {
                "property": "color",
                "type": "identity"
              },
              "fill-opacity": {
                "property": "coloropacity",
                "type": "identity"
              }
            },
            "id": "country",
            "source": "dlsgis_his_regime",
            "type": "fill",
            "minzoom": 0,
            "source-layer": "history_country_101001_"+year
          })




    }
  },
  mounted: async function () {
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      this.direction = 'btt'
    }
    if (localStorage.getItem("currentYear")) {
      this.currentYear = Number(localStorage.getItem("currentYear"))
    } else {
      this.currentYear = this.defaultYear
    }
    if (localStorage.getItem("zoom")) {
      this.zoom = Number(localStorage.getItem("zoom"))
    }
    if (localStorage.getItem("center")) {
      this.center = JSON.parse(localStorage.getItem("center"))
    }
    this.map = new mapboxgl.Map({
      container: "map",
      style: this.style,
      pitchWithRotate: false,
      dragRotate: false,
      zoom: this.zoom,
      minZoom: this.minZoom,
      maxZoom: this.maxZoom,
      center: this.center,
      preserveDrawingBuffer: true
    });
    this.map._authenticate = function () { };
    this.map.on("style.load", () => {
      this.map.setFog({});
    });
    this.map.style.glyphManager.localGlyphMode = 0;
    this.map.addControl(new ProjectionControl());
    const that = this
    this.map.on('click', async (e) => {
      console.log(e.lngLat);
      const features = this.map.queryRenderedFeatures(
        e.point,
        { layers: ['country', 'country_top', 'country_comb_con'] }
      );
      if (features.length > 0) {
        console.log(features)
        if (features.length == 1) {
          this.map.setFilter('country-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
          this.map.setFilter('country-top-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
          this.map.setFilter('country-comb-highlight', ['==', 'territory_id', features[0].properties['territory_id']])
          this.info = features[0].properties
          let { data } = await this.$http.get(Config.host + '/api/territory/info?territoryId=' + this.info.territory_id)
          console.log(data)
          this.info['text'] = data.data['descriptionEn']
          this.drawer = true

        } else {
          let html = '<div><ul class="pop-ul">'
          for (let i = 0; i < features.length; i++) {
            html += '<li class="comb_popup" tid=' + features[i].properties['territory_id'] + '>' + features[i].properties['title_en'] + '</li>'
          }
          html += '</ul></div>'
          const popup = new mapboxgl.Popup({ closeButton: false }).setLngLat(e.lngLat).setHTML(html).addTo(this.map);
          let lis = document.querySelectorAll(".pop-ul li");
          for (let index = 0; index < lis.length; index++) {
            const li = lis[index];
            li.onclick = async function () {
              let tid = this.getAttribute('tid')
              that.map.setFilter('country-highlight', ['==', 'territory_id', tid])
              that.map.setFilter('country-top-highlight', ['==', 'territory_id', tid])
              that.map.setFilter('country-comb-highlight', ['==', 'territory_id', tid])
              popup.remove();
              let { data } = await that.$http.get(Config.host + '/api/territory/info?territoryId=' + tid)
              console.log(data)
              that.info['title_en'] = this.innerHTML
              that.info['text'] = data.data['descriptionEn']
              that.drawer = true
            }
          }
        }

      } else {
        this.drawer = false
        this.map.setFilter('country-highlight', ['==', 'territory_id', ''])
        this.map.setFilter('country-top-highlight', ['==', 'territory_id', ''])
        this.map.setFilter('country-comb-highlight', ['==', 'territory_id', ''])
      }

    });
    this.map.on('mouseenter', 'country_top', (e) => {
      const features = this.map.queryRenderedFeatures(
        e.point,
        { layers: ['country_top',] }
      );
      if (features.length > 0) {
        console.log(features)
        this.map.setFilter('country_top_hover', ['==', 'territory_id', features[0].properties['territory_id']])
      }
    });
    this.map.on('mouseleave', 'country_top', () => {
      this.map.setFilter('country_top_hover', ['==', 'territory_id', ''])
    });

    this.map.on('mouseenter', 'country', (e) => {
      const features = this.map.queryRenderedFeatures(
        e.point,
        { layers: ['country',] }
      );
      if (features.length > 0) {
        this.map.setFilter('countryborder_hover', ['==', 'territory_id', features[0].properties['territory_id']])
        this.map.setFilter('country_hover', ['==', 'territory_id', features[0].properties['territory_id']])
      }
    });
    this.map.on('mouseleave', 'country', () => {
      this.map.setFilter('countryborder_hover', ['==', 'territory_id', ''])
      this.map.setFilter('country_hover', ['==', 'territory_id', ''])
    });
    this.map.on('mouseenter', 'country_comb_con', (e) => {
      const features = this.map.queryRenderedFeatures(
        e.point,
        { layers: ['country_comb_con',] }
      );
      if (features.length > 0) {
        this.map.setFilter('country_comb_line_hover', ['==', 'territory_id', features[0].properties['territory_id']])
      }
    });
    this.map.on('mouseleave', 'country_comb_con', () => {
      this.map.setFilter('country_comb_line_hover', ['==', 'territory_id', ''])
    });
    this.map.on('zoomend', () => {
      localStorage.setItem('zoom', this.map.getZoom())
    });
    this.map.on('moveend', () => {
      localStorage.setItem('center', "[" + this.map.getCenter().lng + "," + map.getCenter().lat + "]")
    });
    window.map = this.map;
  },
  watch: {
    currentYear(newVal, oldVal) {
      console.log(newVal)
      localStorage.setItem("currentYear", newVal)
      this.$refs.ruler.setValue(newVal)
    }
  }
};
</script>
<style scoped >
::v-deep .el-drawer {
  background-color: #f1e9d2 !important
}

::v-deep .el-drawer__header {
  margin-bottom: 7px;
  background-color: #f1e9d2 !important;
}

#mapp {
  width: 100%;
  height: 100%;
}

#map {
  width: 100%;
  height: 100%;
}


#rule {
  position: fixed;
  bottom: 0;
  left: 0;
}

.year-input {
  position: fixed;
  bottom: 50px;
  -webkit-appearance: none;
  left: 50%;
  transform: translateX(-50%);
}

.info-text {
  word-spacing: inherit;
  word-break: auto-phrase;
  text-align: -webkit-match-parent;
  margin: 10px;
  line-height: 30px;
  font-size: 18px;
  background-color: #f1e9d2 !important;
}





.search {
  position: fixed;
  top: 22px;
  left: 10px;
}

.el-button {
  padding: 0 !important;
}

li,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.el-dropdown {
  vertical-align: top;
}
</style>