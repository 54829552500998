/* eslint-disable */
let host = location.protocol+"//"+location.hostname
let fehost = location.protocol+"//"+location.hostname
switch(process.env.NODE_ENV){
  case 'development':{
    host = 'https://timelinegeo.com'
    fehost = 'https://timelinegeo.com'
    break
  }

}

export const Config = {
  host: host,
  fehost: fehost
}