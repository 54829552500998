<template>
  <el-container>
    <div class="out" v-show="isLogin && !showRegist">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="90px" class="demo-ruleForm">
        <el-form-item label="username" prop="username">
          <el-input v-model="ruleForm.username"></el-input>
        </el-form-item>
        <el-form-item label="password" prop="password">
          <el-input v-model="ruleForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">login</el-button>
          <el-button plain @click="toRegist">regist</el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <img src="@/assets/github.png" @click="go('1')" class="github" />
      </el-row>
    </div>
    <div class="out" v-show="!isLogin">
      logining...
    </div>
    <div class="out" v-show="showRegist">
      <el-form :model="ruleForm" :rules="rules" ref="ruleFormRegist" label-width="90px" class="demo-ruleForm">
        <el-form-item label="username" prop="username">
          <el-input v-model="ruleForm.username"></el-input>
        </el-form-item>
        <el-form-item label="password" prop="password">
          <el-input v-model="ruleForm.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="confirmpass" prop="confirmPassword">
          <el-input v-model="ruleForm.confirmPassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="email" prop="email">
          <el-input v-model="ruleForm.email"></el-input>
        </el-form-item>
        <el-form-item label="captcha" prop="captcha">
          <el-input v-model="ruleForm.captcha">
            <template slot="append"><div style="width: 100px;height: 35px;"><img @click="refreshCaptcha" ref="captchaImg" :src="captchaImg" :border="0" style="width: 100%;object-fit: cover;cursor: pointer;"></div></template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="regist('ruleFormRegist')">regist</el-button>
          <el-button type="info" @click="resetForm('ruleFormRegist')">reset</el-button>
        </el-form-item>
      </el-form>
      <el-row>
        <img src="@/assets/github.png" @click="go('1')" class="github" />
      </el-row>
    </div>
  </el-container>
</template>
<script>
/* eslint-disable */
import { Config } from '../config/config.js'
export default {
  data() {
    return {
      isLogin: true,
      showRegist: false,
      captchaImg : '',
      ruleForm: {
        username: '',
        password: '',
        confirmPassword:'',
        captcha: ''
      },
      rules: {
        username: [
          { required: true, message: 'input username', trigger: 'blur' },
        ],
        password: [
          { required: true, message: 'input password', trigger: 'blur' }
        ],
        confirmPassword: [
          { required: true, message: 'input confirmPassword', trigger: 'blur' }
        ],
        email: [
          { required: true, message: 'input email', trigger: 'blur' }
        ],
        captcha: [
          { required: true, message: 'input captcha', trigger: 'blur' }
        ]
      },
      host: Config.host
    }
  },
  components: {

  },
  mounted: async function () {

    let { data } = await this.$http.get(Config.host + '/api/captcha/image?type=char' )
    this.captchaImg = data['data']
    if (window.location.search.split("code=").length > 1) {
      let code = window.location.search.split("code=")[1]
      console.log(code)
      this.isLogin = false
      let { data } = await this.$http.get(Config.host + '/api/login/github?code=' + code)
      console.log(data)
      localStorage.setItem('auth', data['data'])
      let { data: res } = await this.$http.get(Config.host + '/api/user/info')
      console.log(res)
      localStorage.setItem('user', JSON.stringify(res['data']))
      window.location.href = Config.fehost +'#/user'
    }
  },
  methods: {
    go(type) {
      if (type == '1') {
        const query = new URLSearchParams({
          loginType: 1,
          client_id: 'Ov23lirYrq8LnlEYb7OH',
          scope: '',
          allow_signup: 'true',
          redirect_uri: Config.fehost+'/#/login'
        })
        const url = `https://github.com/login/oauth/authorize?${query.toString()}`
        window.location.href = url
      }
    },
    submitForm(formName) {
      let that = this
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(that.ruleForm.username)
          console.log(that.ruleForm.password)
          let { data } = await that.$http.post(Config.host + '/api/user/login', { "username": that.ruleForm.username, "password": that.ruleForm.password })
          console.log(data)
          if (data['code'] == 200) {
            localStorage.setItem('auth', data['data'])
            let { data: res } = await that.$http.get(Config.host + '/api/user/info')
            console.log(res)
            if (res['code'] == 200) {
              localStorage.setItem('user', JSON.stringify(res['data']))
              window.location.href = Config.fehost +'#/user'
            } else {
              that.$message(res['message']);
            }
          } else {
            that.$message(data['message']);
          }

        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    toRegist(){
      this.showRegist = true
    },
    regist(formName) {
      let that = this
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          console.log(that.ruleForm.username)
          console.log(that.ruleForm.password)
          console.log(that.ruleForm.confirmPassword)
          if (that.ruleForm.password!==that.ruleForm.confirmPassword){
            this.$message.error("confirmPassword error")
            return false;
          }
          let { data } = await that.$http.post(Config.host + '/api/user/regist', { "username": that.ruleForm.username, "password": that.ruleForm.password , 
          "email": that.ruleForm.email,
          "captcha": that.ruleForm.captcha,
          })
          console.log(data)
          if (data['code'] == 200) { 
            that.$message(data['message']);
            this.showRegist = false
          } else {
            that.$message(data['message']);
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async refreshCaptcha(){
      this.$refs['captchaImg'].src=this.host + '/api/captcha/image?type=char&r=' +Math.random()
      let { data } = await this.$http.get(Config.host + '/api/captcha/image?type=char' )
      this.captchaImg = data['data']
    }
  }
}

</script>
<style scoped>
.el-container {
  height: 100%;
  background-color: #f1e9d2;
}

p {
  text-align: left;
}

.el-header {
  padding: 0;
  background-color: #f1e9d2;
}

li {
  text-align: left;
  line-height: 30px;
}

.out {

  position: absolute;
  /* 绝对定位 */
  top: 50%;
  left: 50%;
  /* 
				确定宽高时使用 
				width: 200px;
				height: 200px;
				左偏移宽度的一半，上偏移宽度的一半 
				margin-left: -100px;
				margin-top: -100px;
			*/
  /* 不确定宽高使用 */
  transform: translate3d(-50%, -50%, 0);
  padding: 20px;
  overflow-y: auto;
  border-radius: 4px;
  background-color: #fff;
  transition: transform 0.3;
}

.github {
  /* background-image: url('@/assets/github.png'); */
  width: 20px;
  height: 20px;
  cursor: pointer;
}
::v-deep .el-input-group__append{
  padding: 0 ;
}
</style>
