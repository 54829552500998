class ProjectionControl {
    constructor() {

    }
    onAdd(map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl mapboxgl-ctrl-group btn-globe';
        this.projButton = document.createElement("button");
        this.projButton.innerHTML = '3d'
        //this.projButton.classList.add("el-icon-bangzhu");
        this.projButton.classList.add("mapboxgl-ctrl-group");
        this.projButton.addEventListener("click", event => {
            if (this.events && this.events.onSelect && this.events.onSelect(event)) {
                return;
            }
            this.swapProjection();
        });
        this._container.appendChild(this.projButton);
        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
    swapProjection() {
        if (this._map.getProjection().name === 'globe') {
            this._map.setProjection('mercator')
            this.projButton.innerHTML = '3d'

        } else {
            this._map.setProjection('globe')
            this.projButton.innerHTML = '2d'
        }
    }
}
export default ProjectionControl